import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import AuthUserContext from './context';
import { withFirebase } from '../firebase';
import { routes } from '../../constants/routes';

const withAuthorization = condition => Component => {
  const WithAuthorization = props => {
    const { firebase, history } = props;

    useEffect(() => {
      firebase.onAuthUserListener(user => !condition(user) ? history.push(routes.login) : null, () => history.push(routes.login));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <AuthUserContext.Consumer>
        {authUser => condition(authUser) ? <Component {...props} authUser={authUser} /> : null}
      </AuthUserContext.Consumer>
    );
  }

  return withRouter(withFirebase(WithAuthorization));
}

export default withAuthorization;
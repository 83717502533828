import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Loader from './components/loader';
import Layout from './components/layout';
import { routes } from './constants/routes';
import { withAuthentication } from './networking/session';

const Accounts = React.lazy(() => import('./pages/accounts'));
const CreateAccount = React.lazy(() => import('./pages/create-account'));
const DemoAccounts = React.lazy(() => import('./pages/demo-accounts'));
const ManageDemoAccount = React.lazy(() => import('./pages/manage-demo-account'));
const ManageData = React.lazy(() => import('./pages/manage-data'));
const MapStyles = React.lazy(() => import('./pages/map-styles'));
const AccountDetails = React.lazy(() => import('./pages/account-details'));
const ProductList = React.lazy(() => import('./pages/product-list'));
const Profile = React.lazy(() => import('./pages/profile'));
const Login = React.lazy(() => import('./pages/login'));
const Home = React.lazy(() => import('./pages/home'));

const App = () => {
  return (
    <Router>
      <Layout>
        <React.Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path={routes.home} component={Home} />
            <Route exact path={routes.login} component={Login} />
            <Route exact path={routes.accounts} component={Accounts} />
            <Route exact path={`${routes.accounts}${routes.createAccount}`} component={CreateAccount} />
            <Route exact path={`${routes.accounts}${routes.demoAccounts}`} component={DemoAccounts} />
            <Route exact path={`${routes.accounts}${routes.demoAccounts}${routes.manageDemoAccount}`} component={ManageDemoAccount} />
            <Route exact path={routes.manageData} component={ManageData} />
            <Route exact path={`${routes.manageData}${routes.mapStyles}`} component={MapStyles} />
            <Route exact path={routes.accountDetails} component={AccountDetails} />
            <Route exact path={`${routes.accountDetails}${routes.productList}`} component={ProductList} />
            <Route exact path={routes.profile} component={Profile} />
          </Switch>
        </React.Suspense>
      </Layout>
    </Router>
  )
}

export default withAuthentication(App);
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colors, fontSizes } from '../styles';

const propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  color: PropTypes.string,
  isSmall: PropTypes.bool,
  shouldIndent: PropTypes.bool
};

const Label = props => {
  const { className, children, color = colors.darkGray, isSmall = true, shouldIndent = true } = props;

  return <InputLabel className={className} color={color} isSmall={isSmall} shouldIndent={shouldIndent}>{children}</InputLabel>
};

Label.propTypes = propTypes;

const InputLabel = styled.label`
  color: ${({ color }) => color};
  margin-bottom: ${({ isSmall }) => isSmall ? 0 : '10px'};
  margin-left: ${({ shouldIndent }) => shouldIndent ? '40px' : 0};
  font-weight: ${({ isSmall }) => isSmall ? 400 : 600};
  font-size: ${({ isSmall }) => isSmall ? fontSizes.label.small.fontSize : fontSizes.label.large.fontSize};
  line-height: ${({ isSmall }) => isSmall ? fontSizes.label.small.fontSize : fontSizes.label.large.fontSize};
`;

export { Label };


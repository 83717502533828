import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colors, fontSizes } from '../styles';

const propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  hasRightIcon: PropTypes.bool
};

const spinner = <svg className="spinner" width="20px" height="20px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle></svg>;

const Button = props => {
  const { className, children, type = 'submit', hasRightIcon = false, color = colors.pureWhite, bgColor = colors.blue, disabled = false, fontSize = fontSizes.label.large.fontSize, loading = false, ...allProps } = props;

  return (
    <Butn type={type} disabled={disabled || loading} className={className} color={color} bgColor={bgColor} hasRightIcon={hasRightIcon} fontSize={fontSize} {...allProps} >
      {loading && spinner}
      {children}
    </Butn>
  );
};

Button.propTypes = propTypes;

const Butn = styled.button`
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
  font-weight: 700;
  font-size: ${({ fontSize }) => fontSize};
  line-height: 1;
  height: 40px;
  border-radius: 20px;
  padding: 0 20px;
  display: inline-flex;
  align-items: center;
  transition-duration: 400ms;

  & > svg {
    margin-right: ${({ hasRightIcon }) => hasRightIcon ? 0 : '5px'};
    margin-left: ${({ hasRightIcon }) => hasRightIcon ? '5px' : 0};
  }

  &:hover:not([disabled]) {
    cursor: pointer;
  }

  .spinner {
    animation: rotator 1.4s linear infinite;
    margin-right: 10px;
  }

  @keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
  }

  .path {
    stroke-dasharray: 100;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation:
      dash 1.4s ease-in-out infinite, 
      colors 5.6s ease-in-out infinite;
  }

  &.disabledGray[disabled] {
    background-color: ${colors.lightGray};
    border-color: ${colors.lightGray};
  }

  @keyframes colors {
    0% { stroke: ${colors.pureWhite}; }
    100% { stroke: ${colors.pureWhite}; }
  }

  @keyframes dash {
    0% { stroke-dashoffset: 100; }
    50% {
      stroke-dashoffset: 100/4;
      transform:rotate(135deg);
    }
    100% {
      stroke-dashoffset: 100;
      transform:rotate(450deg);
    }
  }
`;

export { Button };


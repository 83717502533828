import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  flexDirection: PropTypes.string,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string
};

const Flexbox = props => {
  const { className, children, flexDirection = 'row', justifyContent = 'normal', alignItems = 'normal' } = props;

  return <Wrapper className={className} flexDirection={flexDirection} justifyContent={justifyContent} alignItems={alignItems}>{children}</Wrapper>
};

Flexbox.propTypes = propTypes;

const Wrapper = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-direction: ${({ flexDirection }) => flexDirection};
  align-items: ${({ alignItems }) => alignItems};
`;

export { Flexbox };

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { withFirebase } from '../networking/firebase';
import { colors } from '../ui-components';
import { routes } from '../constants/routes';

const propTypes = {
  className: PropTypes.string,
  authUser: PropTypes.object
};

const Notify = ({ authUser }) => {
  return (
    <>
      {!authUser.hasUpdatedTempPassword && <Wrapper>So...you might want to <NavLink to={routes.profile}>change your temporary password</NavLink> right away.</Wrapper>}
    </>
  )
}

Notify.propTypes = propTypes;

export default withFirebase(Notify);

const Wrapper = styled.div`
  background-color: ${colors.red};
  padding: 10px;
  text-align: center;
  font-size: 12px;
  color: ${colors.pureWhite};
  font-weight: 700;

  a {
    color: ${colors.pureWhite};
    text-decoration: underline;
  }
`;
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { User, Lock, Search, Mail, Phone, Smile, MapPin, Globe, XCircle, Upload } from 'react-feather';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/index.min.css';

import GOOGLE from '../../constants/google';
import { colors } from '../styles';

const propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  bottomMargin: PropTypes.number,
  icon: PropTypes.string,
  onSelect: PropTypes.func
};

const ICONS = {
  user: User,
  lock: Lock,
  search: Search,
  mail: Mail,
  phone: Phone,
  smile: Smile,
  mappin: MapPin,
  globe: Globe
}

const establishmentRequest = {
  types: ['establishment']
}

const addressRequest = {
  types: ['address']
}

const Input = React.forwardRef((props, ref) => {
  const { className, children, initialValue, name, isSmall, color = colors.darkGray, type = 'text', requestType = 'address', placeholder = '', bottomMargin = 30, icon, hasCloseIcon, currentLogo, onClose, onSelect, ...allProps } = props;
  const [imagePath, setImagePath] = useState();

  const handleInputFileChange = e => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = e => setImagePath(e.target.result);
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  if (type === 'tel') {
    return (
      <Container className={className} bottomMargin={bottomMargin}>
        {icon && <Phone color={colors.lightGray} />}
        <InputMaskField ref={ref} name={name} placeholder={placeholder} mask='999-999-9999' type={'tel'} autoComplete={'none'} {...allProps} />
      </Container>
    )
  }

  if (type === 'autocomplete') {
    const { value } = props;
    const initialValue = value;
    return (
      <Container className={className} bottomMargin={bottomMargin}>
        {icon && <MapPin color={colors.lightGray} />}
        <GooglePlacesAutocomplete initialValue={initialValue} autocompletionRequest={requestType === 'establishment' ? establishmentRequest : addressRequest} apiKey={GOOGLE.APIKEY} ref={ref} placeholder={placeholder} onSelect={data => onSelect(data)} {...allProps} />
        {hasCloseIcon && <CloseIcon color={colors.red} onClick={onClose} data-tip={'Remove Location'} style={{ marginLeft: 10 }} />}
      </Container>
    )
  }

  if (type === 'file') {
    return (
      <Container className={`${className} inputfile`} bottomMargin={bottomMargin}>
        <InputField ref={ref} id={name} name={name} color={color} type={type} placeholder={placeholder} autoComplete={'none'} onChange={handleInputFileChange} {...allProps}>{children}</InputField>
        {imagePath && <ImageContainer>
          <img src={imagePath} alt={''} />
        </ImageContainer>}
        {currentLogo && !imagePath && <ImageContainer>
          <img src={currentLogo} alt={''} />
        </ImageContainer>}
        <FileLabel htmlFor={name} hasFile={imagePath ? true : false}><Upload />{imagePath || currentLogo ? 'Change image' : 'Select an image'}</FileLabel>
      </Container>
    )
  }

  if (icon) {
    const IconHandler = ICONS[icon];
    return (
      <Container className={className} bottomMargin={bottomMargin}>
        <IconHandler color={colors.lightGray} />
        <IconInputField ref={ref} name={name} type={type} placeholder={placeholder} autoComplete={'none'} {...allProps} />
      </Container>
    )
  }

  return <InputField ref={ref} className={className} name={name} color={color} type={type} placeholder={placeholder} bottomMargin={bottomMargin} isSmall={isSmall} {...allProps}>{children}</InputField>
});

Input.propTypes = propTypes;

const InputField = styled.input`
  &[type=date], 
  &[type=datetime-local], 
  &[type=datetime], 
  &[type=email], 
  &[type=month], 
  &[type=number], 
  &[type=password], 
  &[type=search], 
  &[type=tel], 
  &[type=text], 
  &[type=time], 
  &[type=url], 
  &[type=week], 
  select, 
  textarea {
    color: ${({ color }) => color} !important;
    margin-bottom: ${({ bottomMargin }) => `${bottomMargin}px`};
    border-color: ${colors.lightGray}; 
    padding: 0 40px;
    border-radius: 40px;
    border-width: 2px;
    height: ${({ isSmall }) => isSmall ? '60px' : '80px'};
    font-size: ${({ isSmall }) => isSmall ? '16px' : '18px'};
    font-weight: 600;
  }

  &.invalid {
    border-color: ${colors.red};
  }

  &.editable {
    border: none;
    padding: 0;
    height: auto;
    margin-bottom: 0;
    border-radius: 0;
    font-size: 26px;
    line-height: 39px;
    font-weight: 800;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ bottomMargin }) => `${bottomMargin}px`};
  padding: 0 40px;
  border-color: ${colors.lightGray}; 
  border-radius: 40px;
  border-width: 2px;
  border-style: solid;
  height: 80px;

  &.invalid {
    border-color: ${colors.red};
  }

  input {
    box-shadow: none;
    border: none;

    &:focus {
      outline: 0 !important;
      box-shadow: none !important;
    }
  }

  &.editable {
    border: none;
    padding: 0;
    height: auto;
    margin-bottom: 0;
    border-radius: 0;

    input {
      font-size: 26px;
      line-height: 39px;
      font-weight: 800;
      padding: 0;
      color: ${colors.darkGray};
    }
  }

  &.inputfile {
    justify-content: space-between;

    input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
  }
`;

const FileLabel = styled.label`
  font-size: ${({ hasFile }) => hasFile ? '14px' : '20px'};
  padding: 10px 0;
  color: ${colors.green};
  cursor: pointer;

  svg {
    margin-right: 10px;
  }

  &:hover {
    color: ${colors.blue};
  }
`;

const ImageContainer = styled.div`
  padding: 10px 0 0;

  img {
    min-width: 100px;
    max-width: 200px;
  }
`;

const IconInputField = styled.input`
  &[type=date], 
  &[type=datetime-local], 
  &[type=datetime], 
  &[type=email], 
  &[type=month], 
  &[type=number], 
  &[type=password], 
  &[type=search], 
  &[type=tel], 
  &[type=text], 
  &[type=time], 
  &[type=url], 
  &[type=week], 
  select, 
  textarea {
    border: none;

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
`;

const InputMaskField = styled(InputMask)`
  border: none !important;

  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }
`;

const CloseIcon = styled(XCircle)`
  &:hover {
    cursor: pointer;
  }
`;

export { Input };
export const fontSizes = {
  h1: {
    fontSize: '65px',
    lineHeight: '80px'
  },
  h2: {
    fontSize: '36px',
    lineHeight: '42px'
  },
  h3: {
    fontSize: '48px',
    lineHeight: '64px'
  },
  h4: {
    fontSize: '24px',
    lineHeight: '35px'
  },
  h5: {
    fontSize: '22px',
    lineHeight: '28px'
  },
  h6: {
    fontSize: '16px',
    lineHeight: '28px'
  },
  label: {
    large: {
      fontSize: '16px',
      lineHeight: '26px'
    },
    small: {
      fontSize: '14px',
      lineHeight: '24px'
    }
  },
  input: {
    large: {
      fontSize: '20px',
      lineHeight: '32px'
    },
    small: {
      fontSize: '18px',
      lineHeight: '18px'
    }
  },
  paragraph: {
    large: {
      fontSize: '20px',
      lineHeight: '32px'
    },
    medium: {
      fontSize: '16px',
      lineHeight: '26px'
    },
    small: {
      fontSize: '14px',
      lineHeight: '20px'
    }
  }
};

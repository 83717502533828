export const colors = {
  orange: '#FFBB00',
  darkGray: '#575F6B',
  lightGray: '#BBBBBB',
  pureWhite: '#ffffff',
  pureBlack: '#000000',
  shadowColor: '#d9d9db',
  green: '#65C08A',
  blue: '#0088FF',
  darkBlue: '#0069d9',
  red: '#FF4600',
  border: '#dfe0e2',
  blue1: '#00b9f1', // rewrite from here
  blue2: '#3f4ea9',
  brightBlue: '#334194',
  lightBlue: '#e9f2f5',
  gray1: '#8e9094',
  gray3: '#f3f3f3',
}
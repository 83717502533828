import React from 'react';

const Loader = props => {
  // const {} = props;

  // TODO: Add bitchin loader here
  return (
    <div>
      Loading...
    </div>
  )
}

export default Loader;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Users, Map, Settings, User } from 'react-feather';

import { withFirebase } from '../networking/firebase';
import { colors, Flexbox, Text, Heading } from '../ui-components';
import { routes } from '../constants/routes';

import logoLight from '../assets/images/mappie-logo-light.svg';

const propTypes = {
  className: PropTypes.string,
  authUser: PropTypes.object
};

const Sidebar = ({ className, firebase, authUser }) => {
  const [companyName, setCompanyName] = useState('Mappie');
  const [avatarImage, setAvatarImage] = useState('');

  useEffect(() => {
    if (!authUser.isAdmin) {
      firebase.getCompanyInformation(authUser.accountId).then(data => {
        const info = data.data();
        setCompanyName(info.companyName);
      })
    }

    if (authUser.photoURL) {
      // firebase.getFileURL('profile-pictures', authUser.photoURL).then(url => {
      //   setAvatarImage(authUser.photoURL);
      // })
      setAvatarImage(authUser.photoURL);
    }
  }, [firebase, authUser]);

  return (
    <Wrapper className={className} flexDirection={'column'} justifyContent={'space-between'}>
      <div>
        <LogoWrapper>
          <Logo src={logoLight} alt='Mappily' />
        </LogoWrapper>
        {authUser.isAdmin && (
          <NavLinkWrapper to={routes.accounts}>
            <IconWrapper>
              <Users color={colors.pureWhite} size={40} strokeWidth={1} />
            </IconWrapper>
            <TitleWrapper>
              <TitleText>Accounts</TitleText>
              <InfoText color={colors.pureWhite}>Manage User Accounts</InfoText>
            </TitleWrapper>
          </NavLinkWrapper>
        )}

        {!authUser.isAdmin && (
          <>
            <NavLinkWrapper to={routes.manageData}>
              <IconWrapper>
                <Map color={colors.pureWhite} size={40} strokeWidth={1} />
              </IconWrapper>
              <TitleWrapper>
                <TitleText>Manage Maps</TitleText>
                <InfoText color={colors.pureWhite}>Upload data and edit your map styles</InfoText>
              </TitleWrapper>
            </NavLinkWrapper>

            <NavLinkWrapper to={routes.accountDetails}>
              <IconWrapper>
                <Settings color={colors.pureWhite} size={40} strokeWidth={1} />
              </IconWrapper>
              <TitleWrapper>
                <TitleText>Account Details</TitleText>
                <InfoText color={colors.pureWhite}>Manage company details and product list</InfoText>
              </TitleWrapper>
            </NavLinkWrapper>
          </>
        )}

        <NavLinkWrapper to={routes.profile}>
          <IconWrapper>
            <User color={colors.pureWhite} size={40} strokeWidth={1} />
          </IconWrapper>
          <TitleWrapper>
            <TitleText>Profile</TitleText>
            <InfoText color={colors.pureWhite}>Update email, password...</InfoText>
          </TitleWrapper>
        </NavLinkWrapper>
      </div>
      <Footer>
        <Flexbox alignItems={'center'}>
          <Avatar photoURL={avatarImage} />
          <div>
            <UserName color={colors.pureWhite} size={6} bottomMargin={0}>{authUser.displayName ?? 'Unknown'}</UserName>
            <UserTitle>{companyName}</UserTitle>
          </div>
        </Flexbox>
      </Footer>
    </Wrapper>
  )
}

Sidebar.propTypes = propTypes;

export default withFirebase(Sidebar);

const Wrapper = styled(Flexbox)`
  max-width: 300px;
  width: 100%;
  background-color: ${colors.blue};
  color: ${colors.pureWhite};
  ${'' /* border-radius: 60px 0 0 60px; */}
`;

const LogoWrapper = styled.div`
  padding: 60px 0;
  text-align: center;
`;

const Logo = styled.img`
  width: 150px;
`;

const NavLinkWrapper = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 40px;
  border-left: 4px solid transparent;
  opacity: 0.5;
  transition: opacity 300ms ease-in-out;

  &:hover, &.active {
    text-decoration: none;
    border-left-color: ${colors.orange};
    opacity: 1;
  }
`;

const IconWrapper = styled.div`

`;

const TitleWrapper = styled.div`
  padding: 0 20px;
`;

const TitleText = styled(Text)`
  font-size: 16px;
  line-height: 1;
  margin-bottom: 5px;
  font-weight: bold;
  color: ${colors.pureWhite};
`;

const InfoText = styled(Text)`
  font-size: 13px;
  line-height: 20px;
  margin: 0;
  font-weight: 300;
`;

const Footer = styled.div`
  padding: 20px;
  margin-bottom: 5px;
`;

const Avatar = styled.div`
  background-color: ${colors.pureWhite};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  background-image: url(${({ photoURL }) => photoURL ? photoURL : ''});
  background-size: cover;
  background-repeat: no-repeat;
`;

const UserName = styled(Heading)`
  line-height: 1;
  margin-bottom: 3px;
`;

const UserTitle = styled(Text)`
  margin-bottom: 0;
  color: ${colors.pureWhite};
  opacity: 0.5;
  font-size: 14px;
  line-height: 1;
`;
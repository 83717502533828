import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthUserContext } from '../networking/session';
import Sidebar from './sidebar';
import Footer from './footer';
import Notify from './notify';

import { GlobalStyle, Flexbox } from '../ui-components';

const propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};

const Layout = props => {
  const { children, className } = props;

  // TODO: add annoucement to inform user to change their temp password

  return (
    <>
      <GlobalStyle headerFont={'Poppins, sans-serif'} bodyFont={'Poppins, sans-serif'} />
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <AuthUserContext.Consumer>
        {authUser => authUser ?
          (
            <>
              <Notify authUser={authUser} />
              <Wrapper flexDirection={'row'}>
                <Sidebar authUser={authUser} />
                <Main className={className}>
                  <div>{children}</div>
                  <Footer />
                </Main>
              </Wrapper>
            </>
          ) : (
            <Wrapper flexDirection={'row'}>{children}</Wrapper>
          )
        }
      </AuthUserContext.Consumer>
    </>
  );
};

Layout.propTypes = propTypes;

export default Layout;

const Wrapper = styled(Flexbox)`
  min-height: 100vh;
`;

const Main = styled.main`
  max-width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 140px 60px 0 60px;
  position: relative;
`;
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

import { collections } from '../../constants/collections';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.firestore = app.firestore();
    this.functions = app.functions();
    this.storage = app.storage();
  }

  // Auth API
  doCreateUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);
  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);
  doSignOut = () => this.auth.signOut();
  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
  doPasswordUpdate = async data => {
    await this.auth.signInWithEmailAndPassword(data.email, data.currentPassword);
    return this.auth.currentUser.updatePassword(data.newPassword);
  };
  doEmailUpdate = email => this.auth.currentUser.updateEmail(email);
  doForgotEmail = email => this.auth.sendPasswordResetEmail(email);

  // User API
  users = () => this.firestore.collection(collections.users);
  user = id => this.firestore.collection(collections.users).doc(id);
  doUserUpdate = (id, data) => this.firestore.collection(collections.users).doc(id).set(data, { merge: true });
  currentUser = () => this.auth.currentUser.uid;
  updateAccountStatus = data => {
    const callable = this.functions.httpsCallable('updateAccountStatus', data);
    return callable(data);
  }
  updateProfile = async data => {
    const currentUser = this.auth.currentUser;
    return currentUser.updateProfile(data);
  }
  sendPasswordUpdateEmail = data => {
    const callable = this.functions.httpsCallable('sendPasswordUpdateEmail', data);
    return callable(data);
  }

  // Accounts API
  doCreateNewAccount = data => {
    const callable = this.functions.httpsCallable('createNewAccount', data);
    return callable(data);
  }
  getAllAccounts = () => {
    const callable = this.functions.httpsCallable('getAccounts');
    return callable();
  }

  // Demo Accounts API
  getDemoAccounts = async () => {
    const snapshot = await this.firestore.collection(collections.demoAccounts).get();
    return snapshot.docs.map(doc => {
      let data = doc.data();
      return { ...data, id: doc.id }
    });
  }
  createDemoAccount = data => this.firestore.collection(collections.demoAccounts).add(data);
  getDemoAccount = async id => {
    const doc = await this.firestore.collection(collections.demoAccounts).doc(id).get();
    let data = doc.data();
    return { ...data, id: doc.id }
  };
  deleteDemoAccount = id => this.firestore.collection(collections.demoAccounts).doc(id).delete();

  // Account Details API
  updateCompanyInformation = (data, accountId) => this.firestore.collection(collections.companyInformation).doc(accountId).set(data, { merge: true });
  getCompanyInformation = accountId => this.firestore.collection(collections.companyInformation).doc(accountId).get();

  // File API
  uploadFile = (filename, folder, data) => {
    const storageRef = this.storage.ref(`${folder}/${filename}`);
    return storageRef.put(data);
  }
  getFileURL = (folder, filename) => this.storage.ref(`${folder}/${filename}`).getDownloadURL();
  updateProfilePhoto = async (filename, folder, data) => {
    const storageRef = this.storage.ref(`${folder}/${filename}`);
    await storageRef.put(data);
    return await storageRef.getDownloadURL();
  }


  // Auth User and Account User Merge
  onAuthUserListener = (next, fallback) => {
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid).onSnapshot(doc => {
          const data = doc.data();

          authUser = {
            uid: authUser.uid,
            email: authUser.email,
            displayName: authUser.displayName,
            phoneNumber: authUser.phoneNumber,
            photoURL: authUser.photoURL,
            ...data
          }

          next(authUser);
        });
      } else {
        fallback();
      }
    })
  }
}

export default Firebase;
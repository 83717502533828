import React, { useState, useEffect } from 'react';
import { AuthUserContext } from '../session';
import { withFirebase } from '../firebase';

const withAuthentication = Component => {
  const WithAuthentication = props => {
    const { firebase } = props;
    const [authUser, setAuthUser] = useState(false);

    useEffect(() => {
      const listener = firebase.onAuthUserListener(user => setAuthUser(user), () => setAuthUser(null));

      return () => listener()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <AuthUserContext.Provider value={authUser}>
        <Component {...props} authUser={authUser} />
      </AuthUserContext.Provider>
    );
  }

  return withFirebase(WithAuthentication);
}

export default withAuthentication;
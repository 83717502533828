import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

import { colors } from '../styles';

const propTypes = {
  className: PropTypes.string,
  isCreatableSelect: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  bottomMargin: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object)
};

const setSelectStyle = (isCreatableSelect, isRegularSelect) => {
  return {
    control: styles => ({
      ...styles,
      border: 'none'
    }),
    valueContainer: styles => ({
      ...styles,
      padding: 0,
      paddingLeft: isRegularSelect ? 20 : 0,
      paddingRight: isRegularSelect ? 20 : 0
    }),
    multiValue: styles => ({
      ...styles,
      backgroundColor: colors.orange,
      color: colors.pureWhite,
      margin: 5,
      borderRadius: 18,
      paddingLeft: 10,
      paddingRight: 10,
    }),
    multiValueLabel: styles => ({
      ...styles,
      backgroundColor: 'transparent',
      color: colors.pureWhite,
      fontSize: 18,
      fontWeight: 600,
      padding: 5,
      paddingLeft: 10,
    }),
    multiValueRemove: styles => ({
      ...styles,
      '&:hover': {
        color: colors.pureWhite,
        backgroundColor: 'transparent',
        cursor: 'pointer'
      }
    }),
    menu: styles => ({
      ...styles,
      display: isCreatableSelect ? 'none' : 'block'
    }),
    input: styles => ({
      ...styles,
      fontSize: 18,
      fontWeight: 600,
      marginLeft: isRegularSelect ? 0 : 20
    }),
    placeholder: styles => ({
      ...styles,
      marginLeft: isRegularSelect ? 0 : 20
    }),
    singleValue: styles => ({
      ...styles,
      fontSize: 18,
      fontWeight: 600,
    }),
    indicatorsContainer: styles => ({
      ...styles,
      display: 'none'
    })
  }
}

const SelectField = props => {
  const { className, options = [], bottomMargin = 30, name, isCreatableSelect, isMultiSelect, onChange, ref } = props;

  if (isCreatableSelect) {
    return (
      <Container className={className} bottomMargin={bottomMargin}>
        <TabableSelect ref={ref} isMulti placeholder={'Start typing...'} name={name} options={options} styles={setSelectStyle(true, false)} onChange={onChange} />
      </Container>
    )
  }

  if (isMultiSelect) {
    return (
      <Container className={className} bottomMargin={bottomMargin}>
        <DowndownSelect ref={ref} isMulti placeholder={'Type Product Name'} name={name} options={options} styles={setSelectStyle(false, false)} onChange={onChange} />
      </Container>
    )
  }

  return (
    <Container className={className} bottomMargin={bottomMargin}>
      <RegularSelect ref={ref} name={name} options={options} onChange={onChange} styles={setSelectStyle(false, true)} />
    </Container>
  )
};

SelectField.propTypes = propTypes;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ bottomMargin }) => `${bottomMargin}px`};
  padding: 10px 20px;
  border-color: ${colors.lightGray}; 
  border-radius: 40px;
  border-width: 2px;
  border-style: solid;
  min-height: 80px;

  &.invalid {
    border-color: ${colors.red};
  }
`;

const DowndownSelect = styled(Select)`
  width: 100%;
  outline: none;

  input {
    box-shadow: none;
    border: none;

    &:focus {
      outline: 0 !important;
      box-shadow: none !important;
    }
  }
`;

const TabableSelect = styled(CreatableSelect)`
  width: 100%;
  outline: none;

  input {
    box-shadow: none;
    border: none;

    &:focus {
      outline: 0 !important;
      box-shadow: none !important;
    }
  }
`;

const RegularSelect = styled(Select)`
  width: 100%;
  outline: none;

  input {
    box-shadow: none;
    border: none;

    &:focus {
      outline: 0 !important;
      box-shadow: none !important;
    }
  }
`;

export { SelectField };
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Flexbox, Text, colors } from '../ui-components';

const propTypes = {
  className: PropTypes.string
};

const Footer = ({ className }) => {
  // const year = new Date().getFullYear();

  return (
    <Wrapper alignItems={'center'} justifyContent={'flex-end'} className={className}>
      <Text style={{ marginBottom: 0, marginRight: 10, fontSize: 14 }}>Need Help?</Text>
      <ContactButn href="mailto:support@mappie.io" rel="noopener noreferrer" target="_blank">Contact Support</ContactButn>
    </Wrapper>
  )
}

Footer.propTypes = propTypes;

export default Footer;

const Wrapper = styled(Flexbox)`
  margin: 100px 0 20px;
`;

const ContactButn = styled.a`
  color: ${colors.pureWhite};
  background-color: ${colors.blue};
  font-weight: 600;
  line-height: 1;
  height: 40px;
  border-radius: 20px;
  padding: 0 20px;
  display: inline-flex;
  align-items: center;
  transition: background-color 400ms ease-in-out;

  &:hover, &:active, &:focus {
    text-decoration: none;
    cursor: pointer;
    color: ${colors.pureWhite};
    background-color: ${colors.darkBlue};
  }
`;
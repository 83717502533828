export const routes = {
  home: '/',
  login: '/login',
  resetPassword: '/reset-password',
  accounts: '/accounts',
  createAccount: '/new-account',
  manageAccount: '/manage',
  demoAccounts: '/demo-accounts',
  manageDemoAccount: '/manage-demo-account',
  manageData: '/manage-data',
  mapStyles: '/map-styles',
  accountDetails: '/account-details',
  productList: '/product-list',
  profile: '/profile'
}
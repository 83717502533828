import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { colors } from '../styles';

const propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
  to: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
  lineHeight: PropTypes.number,
};

const InternalLink = props => {
  const { className, children, to, type = 'link', color = colors.darkGray, fontSize, lineHeight, ...allProps } = props;

  return type === 'button' ? <StyledButton className={className} to={to} {...allProps}>{children}</StyledButton> : <StyledLink className={className} color={color} fontSize={fontSize} lineheight={lineHeight} to={to} {...allProps}>{children}</StyledLink>;
};

InternalLink.propTypes = propTypes;

const StyledLink = styled(Link)`
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : 'inherit'};
  line-height: ${({ lineheight }) => lineheight ? `${lineheight}px` : 'inherit'};
  text-decoration: underline;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
`;

const StyledButton = styled(Link)`
  background-color: ${colors.green};
  border: ${colors.green};
  color: ${colors.pureWhite};
  height: 40px;
  border-radius: 20px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  font-weight: 800;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  font-size: 14px;
  line-height: 1.5;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &.blue {
    background-color: ${colors.blue};

    &:hover {
      background-color: ${colors.darkBlue};
    }
  }

  & > svg {
    margin-left: 10px;
  }

  &:hover {
    color: ${colors.pureWhite};
    text-decoration: none;
    background-color: ${colors.blue}
  }
`;

export { InternalLink };

